import React from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface BalanceCellProps {
  value: number;
}

const BalanceCell: React.FC<BalanceCellProps> = ({ value }) => {
  const formatBalance = (val: number): string => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
    }).format(val);
  };

  const formatTBalance = (val: number): string => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
    }).format(val);
  };
  const displayValue: string = value > 0 && value < 0.0001 ? '> 0.0000' : formatBalance(value);

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div className="text-sm text-muted-foreground mx-4">
            {displayValue}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{formatTBalance(value)}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default BalanceCell;