/* eslint-disable @typescript-eslint/no-explicit-any */

import AddressCell from "@/components/misc/AddressCell";
import Opfuscate from "@/components/misc/Opfuscate";
import TextCell from "./TextCell";
import KeysDisplay from "./KeysDisplay";
import DisplayCell from "./DisplayCell";
import BooleanCell from "./BooleanCell";
import KeyDisplay from "./KeyDisplay";

export const colsBALA = [
  {
    id: "entry.network",
    name: "Network",
    width: 100,
  },
  {
    id: "entry.asset",
    name: "Asset",
    width: 200,
  },
  {
    id: "entry.address",
    name: "Address",
    width: 400,
  },
];

export const colsEXCH = [
  {
    id: "entry.network",
    name: "Exchange",
    width: 250,
  },
  {
    id: "entry.public",
    name: "Public Key",
    width: 100,
    cell: (val: string) => <AddressCell fullAddress={val} />,
  },
  {
    id: "entry.name",
    name: "Account Name",
    width: 220,
    cell: (val: string) => {
          return (
            <div className="text-sm text-muted-foreground mx-4">
              <TextCell value={val} />
            </div>
          );

        },
  },
  {
    id: "entry.passphrase",
    name: "Passphrase",
    width: 230,
    cell: (row: string) => <Opfuscate fullText={row} isError={false} />,
  },
];

export const colsPUBK = [
  {
    id: "entry.network",
    name: "Network",
    width: 50,
  },
  {
    id: "entry.single",
    name: "Single Signature?",
    width: 50,
        cell: (val: any) => {
          return (
            <div>
              <BooleanCell value={val} />
            </div>
          );
    },
  },
  {
    id: "entry.min",
    name: "Minimum Signers",
    width: 50,
        cell: (val: number) => {
          return (
            <div>
              <DisplayCell value={val} />
            </div>
          );
    },
  },
  {
    id: "entry.max",
    name: "Maximum Signers",
    width: 50,
        cell: (val: number) => {
          return (
            <div>
              <DisplayCell value={val} />
            </div>
          );
    },
  },
  {
    id: "entry.keys",
    name: "Keys",
    width: 300,
    cell: ( row: string[] ) => {
      return <KeysDisplay keys={row} />
    },
  },
];

export const colsRISK = [
  {
    id: "entry.address",
    name: "Address",
    width: 650,
  },
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
];

export const colsSIGN = [
  {
    id: "entry.pubkey",
    name: "Public Key",
    width: 200,
    cell: (row: string) => <KeyDisplay fullText={row} isError={false} />,
  },

    {
      id: "entry.message",
      name: "Message",
      width: 400,
      cell: (row: string) => <TextCell value={row} />,
    },
  {
    id: "entry.signature",
    name: "Signature",
    width: 200,
    cell: (row: string) => <AddressCell fullAddress={row} />,
  }
];

export const colsMap: {
  [key: string]: { id: string; name: string; width: number; cell?: any }[];
} = {
  BALA: colsBALA,
  EXCH: colsEXCH,
  PUBK: colsPUBK,
  RISK: colsRISK,
  SIGN: colsSIGN,
};

export const filtersMap: {
  [key: string]: { accessorKey: string; placeholder: string };
} = {
  BALA: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
  EXCH: { accessorKey: "entry.network", placeholder: "Filter Exchanges..." },
  PUBK: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
  RISK: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
  SIGN: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
};
