import { ReactNode, useEffect, useRef, useState, useCallback } from "react";
import { User } from "@/types/auth.type";
import { io } from "socket.io-client";
import { ApiWebSocketCtx, CustomApiWebSocket } from "./context";
import { DownloadResponse } from "@/types/report.type";
import { useToast } from "@/components/ui/use-toast";

const MAX_RECONNECTION_ATTEMPTS = 10;
const INITIAL_RECONNECTION_DELAY = 1000;
const MAX_RECONNECTION_DELAY = 30000;
const PING_INTERVAL = 25000;
const PING_TIMEOUT = 5000;

const ApiWebSocketCtxProvider = ({ children }: { children?: ReactNode }) => {
  const socketRef = useRef<CustomApiWebSocket | null>(null);
  const [socket, setSocket] = useState<CustomApiWebSocket | null>(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const reconnectAttemptsRef = useRef(0);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout>();
  const pingTimeoutRef = useRef<NodeJS.Timeout>();
  const { toast } = useToast();
  
  const user: User | null = JSON.parse(sessionStorage.getItem("user") || "null");
  const token = user?.token;

  // Calculate exponential backoff delay
  const getReconnectDelay = useCallback(() => {
    const attempt = reconnectAttemptsRef.current;
    const delay = Math.min(
      INITIAL_RECONNECTION_DELAY * Math.pow(2, attempt),
      MAX_RECONNECTION_DELAY
    );
    return delay + Math.random() * 1000; // Add jitter
  }, []);

  const resetPingTimeout = useCallback(() => {
    if (pingTimeoutRef.current) {
      clearTimeout(pingTimeoutRef.current);
    }
    
    pingTimeoutRef.current = setTimeout(() => {
      console.log("Ping timeout - attempting reconnect");
      if (socketRef.current) {
        socketRef.current.disconnect();
        connectSocket();
      }
    }, PING_TIMEOUT);
  }, []);

  const connectSocket = useCallback(() => {
    if (socketRef.current?.connected) {
      console.log("Socket already connected");
      return;
    }

    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }

    if (reconnectAttemptsRef.current >= MAX_RECONNECTION_ATTEMPTS) {
      console.error("Max reconnection attempts reached");
      toast({
        title: "Connection Failed",
        description: "Unable to establish connection after multiple attempts. Please refresh the page.",
        variant: "destructive",
      });
      return;
    }

    const socketInstance: CustomApiWebSocket = io(import.meta.env.VITE_API_BASE_WS, {
      transports: ["websocket"],
      upgrade: false, // Disable transport upgrading for more stable connections
      withCredentials: true,
      reconnection: false, // We'll handle reconnection manually
      timeout: 10000,
      auth: { token },
      query: {
        clientTime: Date.now().toString(),
      },
    }) as CustomApiWebSocket;

    socketRef.current = socketInstance;
    setSocket(socketInstance);

    // Connection event handlers
    socketInstance.on("connect", () => {
      console.log("Connected to WebSocket with ID:", socketInstance.id);
      setIsSocketConnected(true);
      reconnectAttemptsRef.current = 0;
    });

    socketInstance.on("disconnect", (reason) => {
      console.log(":: Disconnected from WebSocket:", reason);
      setIsSocketConnected(false);
      
      if (pingTimeoutRef.current) {
        clearTimeout(pingTimeoutRef.current);
      }

      // Don't attempt to reconnect if the disconnection was intentional
      if (reason === "io client disconnect" || reason === "io server disconnect") {
        return;
      }

      reconnectAttemptsRef.current++;
      const delay = getReconnectDelay();
      
      // Only show toast for critical disconnections after multiple attempts
      if (reconnectAttemptsRef.current >= MAX_RECONNECTION_ATTEMPTS - 2) {
        toast({
          title: "Connection Issues",
          description: "Having trouble maintaining connection. Please check your internet.",
          variant: "destructive",
          duration: 5000,
        });
      }

      reconnectTimeoutRef.current = setTimeout(connectSocket, delay);
    });

    socketInstance.on("connect_error", (err: Error) => {
      console.error("WebSocket connection error: ", err.message);
      
      // Only show toast for critical connection errors after multiple attempts
      if (reconnectAttemptsRef.current >= MAX_RECONNECTION_ATTEMPTS - 2 && !err.message.includes("xhr poll error")) {
        toast({
          title: "Connection Error",
          description: "Unable to establish connection. Please check your internet.",
          variant: "destructive",
          duration: 5000,
        });
      }
    });

    // Ping/Pong for connection health monitoring
    socketInstance.on("pong", () => {
      resetPingTimeout();
    });

    // Custom event handlers
    socketInstance.on("reportDownload", (data: DownloadResponse) => {
      console.log("Report downloaded:", data);
    });

    socketInstance.connect();
  }, [token, toast, getReconnectDelay, resetPingTimeout]);

  // Initialize connection
  useEffect(() => {
    connectSocket();

    return () => {
      console.log("Cleaning up WebSocket connection...");
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (pingTimeoutRef.current) {
        clearTimeout(pingTimeoutRef.current);
      }
      if (socketRef.current) {
        socketRef.current.off("connect");
        socketRef.current.off("disconnect");
        socketRef.current.off("connect_error");
        socketRef.current.off("pong");
        socketRef.current.off("reportRunEntries");
        socketRef.current.off("reportDownload");
        socketRef.current.disconnect();
      }
    };
  }, [connectSocket]);

  // Ping interval for connection monitoring
  useEffect(() => {
    if (!socketRef.current) return;

    const interval = setInterval(() => {
      if (socketRef.current?.connected) {
        socketRef.current.emit("ping");
        resetPingTimeout();
      }
    }, PING_INTERVAL);

    return () => {
      clearInterval(interval);
      if (pingTimeoutRef.current) {
        clearTimeout(pingTimeoutRef.current);
      }
    };
  }, [resetPingTimeout]);

  return (
    <ApiWebSocketCtx.Provider value={{ socket, isSocketConnected }}>
      {children}
    </ApiWebSocketCtx.Provider>
  );
};

export default ApiWebSocketCtxProvider;