/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogTrigger } from "@/components/ui/dialog";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import { FormFieldRenderer } from "./FormFieldRenderer";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getSchemasBySourceType } from "@/zod/source.zod";
import { SourceEntry, Subaccount } from "@/types/sources.type";
import { PlusCircle } from "lucide-react";
import { SubaccountEntry } from "@/components/misc/SubaccountEntry";
import PublicKey from "@/components/misc/PublicKey";
import { Separator } from "@/components/ui/separator";

interface SubaccountFormData {
  network: string;
  public: string;
  private: string;
  passphrase: string;
  name: string;
}

interface SubaccountManagerProps {
  sourceType: string;
  subaccounts: Subaccount[];
  main?: SourceEntry | null;
  onSubaccountsChange: (newSubaccounts: Subaccount[]) => void;
  dynamicFormFields: any;
  assetsForNetwork: any[];
  fetchAssetsForNetwork: (network: string) => Promise<void>;
}

export const SubaccountManager: React.FC<SubaccountManagerProps> = ({
  sourceType,
  subaccounts,
  main,
  onSubaccountsChange,
  dynamicFormFields,
  assetsForNetwork,
  fetchAssetsForNetwork,
}) => {
  const [editingSubaccountIndex, setEditingSubaccountIndex] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const schema: any = getSchemasBySourceType(sourceType);
  const subaccountForm = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      network: main ? main.network : '',
      public: "",
      private: "",
      passphrase: "",
      name: "",
    },
  });

  const addSubaccount: SubmitHandler<SubaccountFormData> = (data, event) => {
    event?.preventDefault();
    setIsSubmitting(true);

    try {
      const newSubaccount: Subaccount = {
        ...data,
        issubaccount: true,
        network: main && main?.network? main.network : '',
      };

      let newSubaccounts: Subaccount[];
      if (editingSubaccountIndex !== null) {
        newSubaccounts = subaccounts.map((sub, index) =>
          index === editingSubaccountIndex ? newSubaccount : sub
        );
      } else {
        newSubaccounts = [...subaccounts, newSubaccount];
      }
  
      onSubaccountsChange(newSubaccounts);

      setEditingSubaccountIndex(null);
      setIsModalOpen(false);
      subaccountForm.reset();
    } catch (error) {
      console.error("Error adding subaccount:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const removeSubaccount = (index: number) => {
    setDeleteIndex(index);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    if (deleteIndex !== null) {
      const newSubaccounts = subaccounts.filter((_, i) => i !== deleteIndex);
      onSubaccountsChange(newSubaccounts);
      setIsDeleteDialogOpen(false);
      setDeleteIndex(null);
    }
  };

  const openEditModal = (index: number | null) => {
    setEditingSubaccountIndex(index && index);
    subaccountForm.reset(subaccounts[index? index : 0]);
    setIsModalOpen(true);
  };

  const renderFormField = (fieldType: string) => {
    if (editingSubaccountIndex !== null && (fieldType === 'public' || fieldType === 'private')) {
      const value = subaccountForm.getValues(fieldType as keyof SubaccountFormData);
      const isPrivateKey = fieldType === 'private' ? true: false;
      return (
        <div key={fieldType}>
          <label htmlFor={fieldType} className="block text-sm font-medium text-gray-700">
            {fieldType.charAt(0).toUpperCase() + fieldType.slice(1)} Key
          </label>

            <PublicKey fullText={value} isPrivateKey={isPrivateKey} />
        </div>
      );
    }
    return (
      <FormFieldRenderer
        isSubaccountForm={true}
        key={fieldType}
        fieldType={fieldType}
        control={subaccountForm.control}
        sourceType={sourceType}
        assetsForNetwork={assetsForNetwork}
        fetchAssetsForNetwork={fetchAssetsForNetwork}
      />
    );
  };

  return (
    <>
      {subaccounts.length === 0 && (
        <div className="flex flex-col items-center gap-1 text-center border rounded-md my-2 border-dotted py-2 bg-muted">
          <div className="flex justify-between items-center w-full px-4"> 
            <div className="text-left">
              <h4 className="text-xl font-bold tracking-tight">
                no subaccounts yet
              </h4>
              <p className="text-sm text-muted-foreground">
                Click "+" to add a subaccount.
              </p>
            </div>
            <Button
              type="button"
              onClick={() => openEditModal(null)}
              variant="ghost"
            >
              <PlusCircle className="w-5 h-5" /> 
            </Button>
          </div>
        </div>
      )}
      {subaccounts.map((subaccount, index) => (
        <SubaccountEntry
          key={index ? index : 1}
          entry={subaccount}
          onEdit={() => openEditModal(index)}
          onDelete={() => removeSubaccount(index)}
        />
      ))}
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogTrigger asChild>
          <Button
            type="button"
            className="gap-1 ml-auto"
            size="sm"
            variant="ghost"
          >
            <PlusCircle className="h-3.5 w-3.5" />
            {editingSubaccountIndex !== null
              ? "Edit Subaccount"
              : "Add Subaccount"}
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {editingSubaccountIndex !== null
                ? "Edit Subaccount"
                : "Add Subaccount"}
            </DialogTitle>
          </DialogHeader>
          <Separator className="my-2" />
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {dynamicFormFields[sourceType].map((fieldType: string) => renderFormField(fieldType))}
            <DialogFooter>
              <Button
                type="submit"
                disabled={isSubmitting}
                size="sm"
                className="my-2 gap-1"
                onClick={(e) => {
                  e.preventDefault();
                  subaccountForm.handleSubmit(addSubaccount)(e);
                }}
              >
                <PlusCircle className="h-3.5 w-3.5" />
                {editingSubaccountIndex !== null
                  ? "Save Changes"
                  : "Add Subaccount"}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <AlertDialog
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the
              subaccount.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDelete}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};