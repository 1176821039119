/* eslint-disable @typescript-eslint/no-explicit-any */
import { z, ZodEffects, AnyZodObject } from "zod";
import Papa from "papaparse";

// Base schema with optional 'network'
const BaseSourceEntrySchema = z.object({
  network: z.string().optional(),
});

// Schema definitions for different source types
const BalaSchema = BaseSourceEntrySchema.extend({
  address: z.string().min(1, { message: "Address is required" }),
  asset: z.string().min(1, { message: "Asset is required" }),
});

const issubaccountPreprocess = (val: unknown) => {
  if (typeof val === "string") {
    const str = val.trim().toLowerCase();
    return str === "true";
  }
  return Boolean(val);
};


const ExchSchema = BaseSourceEntrySchema.extend({
  public: z.string().min(1, { message: "Public key is required" }),
  private: z.string().min(1, { message: "Private key is required" }),
  issubaccount: z.preprocess(issubaccountPreprocess, z.boolean()),
  name: z.string().optional(),
  passphrase: z.string().optional(),
}).superRefine((data, ctx) => {
  if (data.name === "OKX" && !data.passphrase) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["passphrase"],
      message: "Passphrase is required when name is 'OKX'",
    });
  }
});

const RiskSchema = BaseSourceEntrySchema.extend({
  address: z.string().min(1, { message: "Address is required" }),
});

const SignSchema = BaseSourceEntrySchema.extend({
  message: z.string().min(1, { message: "Message is required" }),
  signature: z.string().min(1, { message: "Signature is required" }),
  pubkey: z.string().min(1, { message: "Public Key is required" }),
});

const PubkSchema = BaseSourceEntrySchema.extend({
  network: z.any(),
  single: z.any(),
  min: z.any(),
  max: z.any(),
  keys: z.any(),
});

const DefiSchema = BaseSourceEntrySchema.extend({
  address: z.string().min(1, { message: "Address is required" }),
  protocol: z.string().min(1, { message: "Protocol is required" }),
});

// Function to get the appropriate schema based on the source type
export const getSchemasBySourceType = (sourceType: string) => {
  switch (sourceType) {
    case "BALA":
      return BalaSchema;
    case "RISK":
      return RiskSchema;
    case "EXCH":
      return ExchSchema;
    case "SIGN":
      return SignSchema;
    case "PUBK":
      return PubkSchema;
    case "DEFI":
      return DefiSchema;
    default:
      return BaseSourceEntrySchema;
  }
};

export const SourceEntrySchema = z.object({
  address: z.string().min(1, { message: "Address is required" }),
  network: z.string().min(1, { message: "Network is required" }),
  asset: z.string().min(1, { message: "Asset is required" }),
});

export const SourceSchema = z.object({
  entryType: z.string(),
  sourceGroupId: z.string(),
  sourceID: z.string(),
  updated: z.number(),
  entry: z.any(),
});

export type Source = z.infer<typeof SourceSchema>;

export const SourceGroupSchema = z.object({
  workspaceId: z.string().optional(),
  sourceGroupId: z.string().optional(),
  sourceName: z.string().min(1, "SourceName is required."),
  updated: z.number().optional(),
  groupType: z.string().min(1, "GroupType is required."),
});

export type SourceGroup = z.infer<typeof SourceGroupSchema>;

// Function to validate and clean CSV file data
export const validateAndCleanFile = (
  fileData: string,
  type: string
): string => {
  const schema = getSchemasBySourceType(type);

  // Extract the inner schema if it's a ZodEffects
  const objectSchema =
    schema instanceof ZodEffects
      ? (schema._def.schema as AnyZodObject)
      : (schema as AnyZodObject);

  const requiredColumns = Object.keys(objectSchema.shape).map((col) =>
    col.toLowerCase()
  );

  // Parse the CSV data synchronously
  const results = Papa.parse(fileData, {
    header: true,
    skipEmptyLines: true,
  });

  // Handle parsing errors
  if (results.errors.length > 0) {
    const errorMessages = results.errors.map((e) => e.message).join(", ");
    throw new Error(`CSV parsing error: ${errorMessages}`);
  }

  const columns = results.meta.fields?.map((col) => col.toLowerCase()) || [];

  // Check for required columns
  const missingColumns = requiredColumns.filter(
    (col) => !columns.includes(col)
  );
  if (missingColumns.length > 0) {
    throw new Error(
      `Missing required columns: ${missingColumns.join(", ")}`
    );
  }

  const parsedData = results.data.map((row: any) => {
    const normalizedRow: any = {};
    requiredColumns.forEach((col) => {
      normalizedRow[col] = row[col] || row[col.toUpperCase()] || "";
    });
    return normalizedRow;
  });

  const csvSchema = z.array(schema);

  // Validate data against the schema
  try {
    csvSchema.parse(parsedData);
    return Papa.unparse(parsedData);
  } catch (e: any) {
    const errorMessages = e.errors.map((err: any) => err.message).join(", ");
    throw new Error(`Data validation error: ${errorMessages}`);
  }
};