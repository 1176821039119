import React from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface BalanceCellProps {
  value: string;
}

const TextCell: React.FC<BalanceCellProps> = ({ value }) => {

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div className="text-muted-foreground mx-8 pl-4">
            {value}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{value}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default TextCell;