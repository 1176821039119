/* eslint-disable @typescript-eslint/no-unused-vars */
import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import AssetLineChart from "@/components/charts/LineChartCard";
import AddressCell from "@/components/misc/AddressCell";
import NetworkAsset from "@/components/misc/NetworkAsset";
import DataTable from "@/components/table/CustomDataTable";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useApiWebSocketCtx } from "@/context/context";
import {
  formatArrayLongDate,
  formatBalance,
  getNameBySymbol,
  lookupAsset,
} from "@/lib/utils";
import useReportsStore from "@/stores/reports.store";
import {
  AssetData,
  ChartDataItem,
  DownloadResponse,
  ExTransactionData,
  TransactionData,
} from "@/types/report.type";
import {
  ArrowLeft,
  TrendingDown,
  Clock,
  Loader2,
  TrendingUp} from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatStatus } from "./Reports";
import TableAsset from "@/components/misc/TableAsset";
import BalanceCell from "@/components/misc/BalanceCell";

function ReportEntry() {
  const navigate = useNavigate();
  const { reportGroupId, reportId } = useParams();
  const reportEntries = useReportsStore((state) => state.reports);

  const setSelectedReportGroup = useReportsStore(
    (state) => state.setSelectedReportGroup
  );
  const getTransactions = useReportsStore((state) => state.getTransactions);
  const getGraphData = useReportsStore((state) => state.getGraphData);
  const loading = useReportsStore((state) => state.loading);
  const { downloadTx } = useReportsStore();
  const { selectedReportGroupData, report,
    currentPage,
    totalPages,
    totalRows,
    pageSize,
    handlePageChange,
    handlePageSizeChange,
   } = useReportsStore();

  const [transactions, setTransactions] = useState<(TransactionData | ExTransactionData)[] >([]);
  const [graphData, setGraphData] = useState<AssetData[]>([]);
  const [downloadError, setDownloadError] = useState<string | null>(null);
  const [downloadType, setDownloadType] = useState<'csv' | 'xls' | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadInitiated, setDownloadInitiated] = useState(false);
  const DOWNLOAD_TIMEOUT = 120000; // 2 minutes
  const [reportFailed, setReportFailed] = useState<boolean>(false);
  console.log("reportEntries", reportFailed);
  const { toast } = useToast();
  const { socket, isSocketConnected } = useApiWebSocketCtx();

  const fetchTransactions = useCallback(
    async (page: number, limit: number) => {
      const response = await getTransactions(reportId || "", limit, page);
      if (response && response.length > 0) {
        setTransactions(response);
      }
    },
    [getTransactions, reportId]
  );

  const colsBALA = [
      {
        id: "blocktime",
        name: "Timestamp",
        cell: (val: number) => {
          const datestamp = formatArrayLongDate([val]);
          return (
            <div className="text-xs text-muted-foreground">
              {datestamp}
            </div>
          );
        },
        width: 220,
      },
      {
        id: "transaction",
        name: "Transaction ID",
        cell: (val: string) => {
          return (
            <div className="text-sm text-muted-foreground mx-4">
        <AddressCell fullAddress={val} />
            </div>
          );

        },
        width: 180,
      },
      {
        id: "before",
        name: "Balance Before",
        cell: (val: number) => {
          return (
            <div className="text-sm text-muted-foreground mx-4">
        <BalanceCell value={val} />
            </div>
          );

        },
        width: 220,
      },
      {
        id: "after",
        name: "Balance After",
        cell: (val: number) => {
          return (
            <div className="text-xs text-muted-foreground mx-4">
        <BalanceCell value={val} />
            </div>
          );

        },
        width: 220,
      },
      {
        id: "change",
        name: "Change",
        cell: (val: string) => {
          const changeValue = parseFloat(val);

          if (isNaN(changeValue)) {
            return <span title="Invalid number"> </span>;
          }

          const Icon =
            changeValue > 0
              ? TrendingUp
              : changeValue < 0
                ? TrendingDown
                : null;
          const textColor =
            changeValue > 0
              ? "text-green-600"
              : changeValue < 0
                ? "text-red-600"
                : "text-black dark:text-gray-200";

          return (
            <span
              title={changeValue.toString()}
              className={`flex items-center`}
            >
              <span className={`${textColor} flex items-center`}>
                {Icon ? (
                  <Icon className="mr-1" size={16} />
                ) : (
                  <span className="mr-4"></span>
                )}
              </span>
              <span className="font-semibold">
                {formatBalance(changeValue)}
              </span>
            </span>
          );
        },
        width: 230,
      },
  ];

  const colsEXCH = [
      {
        id: "datetime",
        name: "Timestamp",
        cell: (val: number) => {
          const datestamp = formatArrayLongDate([val]);
          return (
            <div className="text-xs text-muted-foreground">
              {datestamp}
            </div>
          );
        },
        width: 220,
      },
      {
        id: "transaction",
        name: "Transaction ID",
        cell: (val: string) => <AddressCell fullAddress={val} />,
        width: 180,
      },
      {
        id: "asset",
        name: "Asset",
        cell: (val: string) => {
          const asset = lookupAsset(val && val);
          
          return (
            <div>
              {asset ? (
                <TableAsset asset={asset} />
              ) : (
                <span className="bg-gray-200 text-gray-700 dark:bg-gray-600 dark:text-gray-100 rounded-full px-4 py-1">
                  {val}
                </span>
              )}
            </div>
          );
        },
        width: 150,
      },
      {
        id: "before",
        name: "Balance Before",
        cell: (val: number) => {
          return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 4,
          }).format(val);
        },
        width: 220,
      },
      {
        id: "after",
        name: "Balance After",
        cell: (val: number) => {
          return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 4,
          }).format(val);
        },
        width: 220,
      },
      {
        id: "change",
        name: "Change",
        cell: (val: string) => {
          const changeValue = parseFloat(val);

          if (isNaN(changeValue)) {
            return <span title="Invalid number"> </span>;
          }

          const Icon =
            changeValue > 0
              ? TrendingUp
              : changeValue < 0
                ? TrendingDown
                : null;
          const textColor =
            changeValue > 0
              ? "text-green-600"
              : changeValue < 0
                ? "text-red-600"
                : "text-black dark:text-gray-200";

          return (
            <span
              title={changeValue.toString()}
              className={`flex items-center`}
            >
              <span className={`${textColor} flex items-center`}>
                {Icon ? (
                  <Icon className="mr-1" size={16} />
                ) : (
                  <span className="mr-4"></span>
                )}
              </span>
              <span className="font-semibold">
                {formatBalance(changeValue)}
              </span>
            </span>
          );
        },
        width: 230,
      },
  ];




  const colsMap: { [key: string]: { id: string; name: string }[] } = {
    BALA: colsBALA,
    EXCH: colsEXCH
  };

  const cols = selectedReportGroupData?.groupType
    ? colsMap[selectedReportGroupData.groupType]
    : [];

    const handleDownload = useCallback((url: string) => {
      try {
        if (!url) {
          throw new Error("Download URL is empty");
        }

        // Validate URL
        const urlObj = new URL(url);
        if (!urlObj.protocol.startsWith('http')) {
          throw new Error("Invalid download URL");
        }

        // Open in new tab
        window.open(url, '_blank');
        setDownloadError(null);
      } catch (error) {
        console.error("Download error:", error);
        setDownloadError(error instanceof Error ? error.message : "Failed to initiate download");
        toast({
          variant: "destructive",
          title: "Download Error",
          description: "Failed to download the file. Please try again.",
          duration: 5000,
        });
      }
    }, [toast]);

  const downloadAdvancedReportTransactions = useCallback(async () => {
    try {
      setIsDownloading(true);
      setDownloadError(null);

      // Wait for socket response or timeout
      const waitForDownload = new Promise<DownloadResponse>((resolve, reject) => {
        const timeout = setTimeout(() => {
          reject(new Error("Download timeout - please try again"));
        }, 300000); // 5 minutes timeout

        socket?.on("reportDownload", (data: DownloadResponse) => {
          clearTimeout(timeout);
          resolve(data);
        });
      });

      // Wait for the socket response
      const downloadData = await waitForDownload;
      
      if (downloadData.data) {
        handleDownload(downloadData.data);
      } else {
        throw new Error("Download failed - please try again");
      }
    } catch (error) {
      console.error("Error downloading transactions:", error);
      setDownloadError(error instanceof Error ? error.message : "Failed to download transactions");
      toast({
        variant: "destructive",
        title: "Download Error",
        description: "Failed to download transactions. Please try again.",
        duration: 5000,
      });
    } finally {
      socket?.off("reportDownload");
      setIsDownloading(false);
    }
  }, [socket, handleDownload, toast]);

  const emitReportDownload = useCallback(async (type: 'csv' | 'xls') => {
    if (!isSocketConnected || !socket || !reportId || isDownloading) {
      toast({
        variant: "destructive",
        title: "Cannot Download",
        description: "Cannot download report: Invalid report ID or connection",
        duration: 5000,
      });
      return;
    }

    setIsDownloading(true);
    setDownloadInitiated(true);
    setDownloadError(null);
    setDownloadType(type as 'csv' | 'xls' | null);

    try {
      // First initiate the download
      await downloadTx(reportId, type.toUpperCase() as 'CSV' | 'XLS');

      toast({
        title: "Generating Report",
        description: `Generating ${type.toUpperCase()} report. This may take a few minutes for larger reports.`,
        duration: 5000,
      });

      // Then register for websocket updates
      socket.emit("register", {
        reference: reportId,
        type,
      });

      // Start listening for download updates
      downloadAdvancedReportTransactions().catch((error) => {
        console.error("Download process error:", error);
        setDownloadError(error instanceof Error ? error.message : "Download process failed");
        setIsDownloading(false);
      });
    } catch (error) {
      console.error("Error initiating download:", error);
      setDownloadError(error instanceof Error ? error.message : "Failed to initiate download");
      setIsDownloading(false);
      setDownloadInitiated(false);
      
      toast({
        variant: "destructive",
        title: "Download Error",
        description: "Failed to initiate download. Please try again.",
        duration: 5000,
      });
    }
  }, [isSocketConnected, toast, socket, reportId, downloadAdvancedReportTransactions, isDownloading, downloadTx]);
  const retryDownload = useCallback(() => {
    toast({
      title: "Retrying Download",
      description: "Please wait...",
      duration: 3000,
    });
    
    emitReportDownload("csv");
  }, [emitReportDownload, toast]);

  useEffect(() => {
    if (!socket || !isSocketConnected) return;

    let timeoutId: NodeJS.Timeout;

    const handleReportDownload = (data: DownloadResponse) => {
      clearTimeout(timeoutId);

      if (!data) return;

      if (data.message?.trim() === "FAILED" || data.error) {
        setReportFailed(true);
        setDownloadError(data.message || "Report generation failed");
        toast({
          variant: "destructive",
          title: "Report Generation Failed",
          description: data.message || "There was a problem generating your report.",
          duration: 5000,
        });
        setIsDownloading(false);
        setDownloadInitiated(false);
        return;
      }

      if (data.data) {
        try {
          handleDownload(data.data);
          setIsDownloading(false);
          setDownloadInitiated(false);
        } catch (error) {
          console.error("Error handling download:", error);
          setDownloadError("Failed to process download");
          retryDownload();
        }
      }
    };

    socket.on("reportDownload", handleReportDownload);

    if (isDownloading && !downloadInitiated) {
      timeoutId = setTimeout(() => {
        toast({
          title: "Still Processing",
          description: "The report is taking longer than usual to generate. Please wait...",
          duration: 5000,
        });
      }, DOWNLOAD_TIMEOUT / 2);
    }

    return () => {
      socket.off("reportDownload", handleReportDownload);
      clearTimeout(timeoutId);
    };
  }, [socket, isSocketConnected, toast, handleDownload, retryDownload, isDownloading, downloadInitiated]);

  useEffect(() => {
    const fetchGraphData = async () => {
      setSelectedReportGroup(reportGroupId || "");
      const tempGraphData: ChartDataItem[] = await getGraphData(reportId || "");
      const formattedData = tempGraphData.map((item) => ({
        datetime: item.datetime,
        after: item.after,
        asset: item.asset,
      }));
      setGraphData(formattedData);
    };
    fetchGraphData();
  }, [reportGroupId, reportId, setSelectedReportGroup, getGraphData]);

  useEffect(() => {
    fetchTransactions(currentPage, pageSize);
  }, [pageSize, fetchTransactions, currentPage]);

  const snapshotFirstEntryDates = reportEntries[0]?.entry?.datetime;
  const snapshotDate = snapshotFirstEntryDates
    ? formatArrayLongDate(snapshotFirstEntryDates)
    : "";

  const groupType = selectedReportGroupData?.groupType || "";

  const reportGroupUrl = `/reports/${selectedReportGroupData?.reportGroupId}`;
  // const handlePageChange = (page: number) =>
  //   setCurrentPage(!isNaN(page) ? page : 1);
  // const handlePageSizeChange = (size: number) =>
  //   setPageSize(!isNaN(size) && size > 0 ? size : 100);


  return (
    <div>
      <section className="mx-auto flex flex-col items-start gap-2 py-2 md:py-2 md:pb-2 lg:py-2 lg:pb-10 max-w-6xl">
        <div className="group inline-flex items-center px-0.1 text-sm font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-pie-chart h-4 w-4"
          >
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
            <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
          </svg>
          <div
            data-orientation="vertical"
            role="none"
            className="shrink-0 bg-border w-[1px] mx-2 h-4"
          ></div>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Dashboard</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <Link to="/reports">Reports</Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <Link to={reportGroupUrl}>
                  {selectedReportGroupData?.reportName}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>
                  Report Entries: {report?.entry.asset}
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            {
            
    selectedReportGroupData && selectedReportGroupData.groupType === "BALA"?
            ( report && (
              <NetworkAsset
                asset={lookupAsset(report && report?.entry.asset)}
                network={lookupAsset(report && report?.entry.network)}
              />
            )):
( report && (
              <NetworkAsset
                asset={lookupAsset(report && report?.entry.network)}
                network={lookupAsset(report && report?.entry.network)}
              />
            ))
            }
            <h1 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl lg:leading-[1.1]">
              Report: {selectedReportGroupData?.reportName}
            </h1>
          </div>
          <Button
            className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-8 rounded-md px-3 text-xs ml-auto"
            title="Go Back to Report Entries"
            variant="outline"
            onClick={() =>
              navigate(`/reports/${selectedReportGroupData?.reportGroupId}`)
            }
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Entries
          </Button>
        </div>
        <div className="flex items-center bg-accent">
          <span className="ml-0 border px-2 rounded-full text-sm">
            {getNameBySymbol(groupType)}
          </span>
          <p className="text-md font-semibold text-gray-800 dark:text-gray-100 ml-4">
            Status:
          </p>
          <span className="ml-2">
            {formatStatus(report?.status || "P", report?.message)}
          </span>

          {["BALA", "EXCH"].includes(groupType) && snapshotDate && (
            <span className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs">
              <span className="relative group inline-flex items-center">
                <Clock className="h-4 w-4 m-2" />
                <span className="absolute left-0 top-full mt-1 px-2 py-1 text-xs text-white bg-gray-800 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  Snapshot Timestamp
                </span>
              </span>
              {snapshotDate}
            </span>
          )}
        </div>
        <div className="flex ml-auto items-center justify-start gap-2 py-2">
          {transactions && transactions.length > 0 && (
            <div className="grid grid-cols-2 gap-4 max-w-[300px]">
              <div>
                <button
                  type="button"
                  id="csv-download-button"
                  onClick={() => !isDownloading && downloadType !== "csv" && emitReportDownload("csv")}
                  disabled={isDownloading}
                  className="sr-only"
                  aria-label="Download CSV"
                />
                <label 
                  htmlFor="csv-download-button"
                  onClick={() => !isDownloading && downloadType !== "csv" && emitReportDownload("csv")}
                  className={`
                    flex flex-col items-center justify-between rounded-md border-2
                    ${isDownloading && downloadType === "csv" 
                      ? "border-primary bg-primary/5" 
                      : "border-border hover:border-border-hover"} 
                    bg-background p-4 
                    hover:bg-accent hover:text-accent-foreground 
                    transition-all duration-200 relative cursor-pointer
                    ${isDownloading && downloadType !== "csv" ? "opacity-50 cursor-not-allowed" : ""}
                    ${!isDownloading || downloadType === "csv" ? "hover:shadow-md" : ""}
                  `}
                >
                  <div className="relative">
                    {isDownloading && downloadType === "csv" ? (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <Loader2 className="h-6 w-6 animate-spin" />
                      </div>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="mb-3 h-6 w-6"
                      >
                        <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                        <polyline points="14 2 14 8 20 8" />
                      </svg>
                    )}
                  </div>
                  <div className="flex flex-col items-center">
                    <span className="text-sm font-medium">CSV</span>
                    {isDownloading && downloadType === "csv" && (
                      <div className="w-full mt-2">
                        <div className="h-1 w-full bg-gray-200 rounded-full overflow-hidden">
                          <div 
                            className="h-full bg-primary animate-pulse rounded-full"
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </label>
              </div>

              <div>
                <button
                  type="button"
                  id="xls-download-button"
                  onClick={() => !isDownloading && downloadType !== "xls" && emitReportDownload("xls")}
                  disabled={isDownloading}
                  className="sr-only"
                  aria-label="Download XLS"
                />
                <label 
                  htmlFor="xls-download-button"
                  onClick={() => !isDownloading && downloadType !== "xls" && emitReportDownload("xls")}
                  className={`
                    flex flex-col items-center justify-between rounded-md border-2
                    ${isDownloading && downloadType === "xls" 
                      ? "border-primary bg-primary/5" 
                      : "border-border hover:border-border-hover"} 
                    bg-background p-4 
                    hover:bg-accent hover:text-accent-foreground 
                    transition-all duration-200 relative cursor-pointer
                    ${isDownloading && downloadType !== "xls" ? "opacity-50 cursor-not-allowed" : ""}
                    ${!isDownloading || downloadType === "xls" ? "hover:shadow-md" : ""}
                  `}
                >
                  <div className="relative">
                    {isDownloading && downloadType === "xls" ? (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <Loader2 className="h-6 w-6 animate-spin" />
                      </div>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="mb-3 h-6 w-6"
                      >
                        <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                        <polyline points="14 2 14 8 20 8" />
                        <path d="M8 13h2" />
                        <path d="M8 17h2" />
                        <path d="M14 13h2" />
                        <path d="M14 17h2" />
                      </svg>
                    )}
                  </div>
                  <div className="flex flex-col items-center">
                    <span className="text-sm font-medium">XLS</span>
                    {isDownloading && downloadType === "xls" && (
                      <div className="w-full mt-2">
                        <div className="h-1 w-full bg-gray-200 rounded-full overflow-hidden">
                          <div 
                            className="h-full bg-primary animate-pulse rounded-full"
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </label>
              </div>

              {downloadError && (
                <div className="col-span-2">
                  <p className="text-sm text-destructive text-center mt-2">
                    Error: {downloadError}
                  </p>
                </div>
              )}
              
              {isDownloading && (
                <div className="col-span-2 text-center text-sm text-muted-foreground mt-6">
                  Generating {downloadType?.toUpperCase()} report...
                </div>
              )}
            </div>
          )}
        </div>
      </section>

      { selectedReportGroupData && selectedReportGroupData.groupType === "BALA" && graphData && graphData.length > 0 && (
        <DashboardMainCard
          header={`Asset: ${report?.entry.asset}`}
          description={report?.entry.address}
        >
          <AssetLineChart data={graphData} />
        </DashboardMainCard>
      )}
      <DashboardMainCard
        header={"Transactions"}
        description={"Report data entry transactions"}
      >
          <DataTable
            columns={cols}
            data={transactions}
            loading={loading}
            pagination={{
              currentPage,
              pageSize,
              totalPages,
              totalRows,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange,
            }}
          />
      </DashboardMainCard>
    </div>
  );
}

export default ReportEntry;
