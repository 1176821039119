import { useEffect } from "react";
import StepFour from "@/components/stepper/StepFour";
import { StepSourceEntries } from "@/components/stepper/StepSourceEntries";
import { StepSelectSource } from "@/components/stepper/StepSelectSource";
import { createReportWithSourceGroups } from "@/services/reportService";
import { Button } from "@/components/ui/button";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  LoaderCircle,
  CirclePlay,
} from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SourceGroupSchema } from "@/zod/source.zod";
import { Form } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import useAuthStore from "@/stores/auth.store";
import useWorkspaceStore from "@/stores/workspaces.store";
import useSourcesStore, {
  useSelectedSourceGroup,
  useSources,
  useSourcesLoading,
} from "@/stores/sources.store";
import { z } from "zod";
import { StepSummary } from "./StepSourceSummary";
import { DisplayTitle } from "@/components/stepper/DisplayTitle";
import { useApiWebSocketCtx } from "@/context/context";
import useReportsStore from "@/stores/reports.store";
import { useNavigate } from "react-router-dom";
import { ToastAction } from "@/components/ui/toast";
type SourceFormType = z.infer<typeof SourceGroupSchema>;

interface MultiFormProps {
  initialStep?: number;
}

export function MultiForm({ initialStep = 1 }: MultiFormProps) {
  const { toast } = useToast();

  const {
    selectedSourceGroupId,
    step,
    nextStep,
    prevStep,
    createSourceGroup,
    fetchSourceEntriesForSelectedGroup,
    setStep,
    setSelectedSourceGroupData,
  } = useSourcesStore();
  const selectedSourceGroup = useSelectedSourceGroup();
  const loading = useSourcesLoading();
  const sources = useSources();
  const email = useAuthStore((state) => state.user?.email);
  const workspaceId = useWorkspaceStore((state) => state.selectedWorkspaceId);
  const setSelectedReportGroup = useReportsStore(
    (state) => state.setSelectedReportGroup
  );
  const fetchAllReportGroups = useReportsStore(
    (state) => state.fetchAllReportGroups
  );
  const { socket } = useApiWebSocketCtx();
  const navigate = useNavigate();
  const { runDate} = useReportsStore();

  const form = useForm<SourceFormType>({
    resolver: zodResolver(SourceGroupSchema),
    defaultValues: {
      groupType: selectedSourceGroup?.groupType || "",
      sourceName: selectedSourceGroup?.sourceName || "",
    },
  });

  useEffect(() => {
    if (selectedSourceGroupId) {
      fetchSourceEntriesForSelectedGroup(selectedSourceGroupId);
    }
    setStep(initialStep);
  }, [
    fetchSourceEntriesForSelectedGroup,
    initialStep,
    selectedSourceGroupId,
    setSelectedSourceGroupData,
    setStep,
  ]);

  const handleStepOneSubmit = async (values: SourceFormType) => {
    if (!email || !workspaceId) {
      toast({
        title: "Missing data",
        description: "Email or workspace ID is missing.",
        variant: "destructive",
      });
      return false;
    }
    try {
       await createSourceGroup(
        workspaceId,
        values.sourceName,
        values.groupType
      );

      toast({
        title: "Source Created",
        description: "You can now add entries to your source.",
      });
      return true;
    } catch (error) {
      toast({
        title: "Uh Oh...",
        description: `Failed to create source group. ${error}`,
        variant: "destructive",
      });
      return false;
    }
  };

  const handleStepTwoValidation = () => {
    if (initialStep == 1 && sources.length == 0) {
      toast({
        title: "No Entries",
        description:
          "You must create at least one source entry before proceeding.",
        variant: "destructive",
      });
      return false;
    }
    return true;
  };

  const handleReportRun = async (runDates: number[]): Promise<boolean> => {
    if (!email || !selectedSourceGroup) return false;

    const payload = {
      email,
      type: selectedSourceGroup.groupType,
      sources: [selectedSourceGroup.sourceGroupId],
      date: runDate.toISOString().split(".")[0] + "Z",
      datetime: [new Date(runDate).getTime()],
    };

    try {
      const response = await createReportWithSourceGroups(
        payload.sources,
        runDates
      );
      const reportId = response.data.reportGroupId;

      if (!socket || !reportId) {
        return false;
      }
      socket.emit("register", { type: "reportgrouprun", reference: reportId });

      await fetchAllReportGroups(100, 1);
      setSelectedReportGroup(reportId);
      toast({
        title: "Report Queued",
        description: "Your report has been queued for processing.",
        action: (
          <ToastAction
            className="bg-black text-white dark:bg-white dark:text-black font-bold py-2 px-4 rounded transition duration-300 ease-in-out hover:bg-gray-800 dark:hover:bg-gray-200"
            altText="Click here to monitor progress"
            onClick={() => {
              navigate(`/reports/${reportId}`);
            }}
          >
            View Run Progress
          </ToastAction>
        ),
        duration: 20000,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: `Failed to queue report run. ${error}`,
        variant: "destructive",
      });
      return false;
    }
    return true;
  };

  const onSubmit = async (values: SourceFormType) => {
    switch (step) {
      case 1: {
        if (
          selectedSourceGroup &&
          selectedSourceGroup.sourceName === values?.sourceName &&
          selectedSourceGroup.groupType === values?.groupType
        ) {
          nextStep();
        } else {
          const success = await handleStepOneSubmit(values);
          if (success) nextStep();
        }
        break;
      }
      case 2: {
        const valid = handleStepTwoValidation();
        if (valid) {
          nextStep();
        }
        break;
      }
      case 3: {
        nextStep();
        break;
      }
      case 4: {
        const runDates = [new Date(runDate).getTime()];
        const success = await handleReportRun(runDates);
        if (success) {
          console.log("Report run queued successfully!");
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div>
      <Form {...form}>
        <form
          id="form"
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-4 lg:relative lg:flex-1"
        >
          <div className="rounded-xl overflow-visible border text-card-foreground shadow dark:border-gray-800 mx-6 flex flex-col gap-2 bg-white p-6 dark:bg-gray-800 px-4 lg:col-span-2 lg:col-start-2 lg:row-span-3 lg:row-start-2 lg:mx-0 lg:-mt-0 lg:px-16 lg:pb-12 lg:shadow-none">
            <DisplayTitle />

            <FormProvider {...form}>
              {step === 1 && <StepSelectSource />}
              {step === 2 && <StepSourceEntries />}
              {step === 3 && <StepSummary />}
              {step === 4 && <StepFour />}
            </FormProvider>
          </div>

          {step < 5 && (
            <div className="bottom-0 left-0 flex w-full justify-between bg-transparent p-4 lg:bottom-0">
              <Button
                type="button"
                className={`${step === 1 ? "invisible" : ""} text-white bg-dark-900 dark:text-black dark:bg-gray-300 dark:hover:bg-gray-200 hover:bg-gray-800`}
                onClick={prevStep}
              >
                <ChevronLeftIcon className="h-4 w-4 mr-2" />
                Back
              </Button>
              <Button
                type="submit"
                disabled={loading}
                className="text-white bg-dark-900 dark:text-black dark:bg-gray-300 dark:hover:bg-gray-200 hover:bg-gray-800"
              >
                {loading ? (
                  <LoaderCircle className="h-4 w-4" />
                ) : step === 4 ? (
                  <>
                    <CirclePlay className="h-4 w-4 mr-2" />
                    Run Report
                  </>
                ) : (
                  <>
                    <ChevronRightIcon className="h-4 w-4 mr-2" />
                    Next
                  </>
                )}
              </Button>
            </div>
          )}
        </form>
      </Form>
    </div>
  );
}
