import {
  CREATE_WORKSPACE_FORM_DESCRIPTION,
  CREATE_WORKSPACE_FORM_HEADER,
} from "@/config/forms";

import { CreateWorkspaceForm } from "../forms/CreateWorkspaceForm";
import { FC } from "react";
import { GeneralSheet } from "./GeneralSheet";

interface WorkspaceSheetProps {
  isOpen: boolean;
  workspaceId: string | undefined;
  setIsOpen: (value: boolean) => void;
}

export const WorkspaceSheet: FC<WorkspaceSheetProps> = ({
  isOpen,
  workspaceId,
  setIsOpen,

}) => {
  return (
    <GeneralSheet
      title={CREATE_WORKSPACE_FORM_HEADER}
      description={
        CREATE_WORKSPACE_FORM_DESCRIPTION
      }
      isEditPopoverOpen={isOpen}
      setIsEditPopoverOpen={setIsOpen}
    >
      <div className="w-full">
    <CreateWorkspaceForm setModal={setIsOpen} workspaceId={workspaceId} />
      </div>
    </GeneralSheet>
  );
};
