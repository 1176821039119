/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect} from "react";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import NetworkSelect from "./NetworkSelect";
import {
  BLOCKCHAIN_NETWORKS,
  EXCHANGE_NETWORKS,
} from "@/config/general";
import { Switch } from "@/components/ui/switch";
import { useWatch, useFormContext } from "react-hook-form";
interface FormFieldRendererProps {
  fieldType: string;
  control: any;
  sourceType: string;
  assetsForNetwork: any[];
  isSubaccountForm: boolean;
  fetchAssetsForNetwork: (network: string) => Promise<void>;
}

export const FormFieldRenderer: React.FC<FormFieldRendererProps> = ({
  fieldType,
  control,
  sourceType,
  assetsForNetwork,
  isSubaccountForm,
  fetchAssetsForNetwork,
}) => {

  const { setValue, getValues } = useFormContext(); 

  const isSingle = useWatch({
    control,
    name: 'single',
  });

  const selectedNetwork = useWatch({
    control,
    name: 'network',
  });


  const networksMaster =
    sourceType === "EXCH"
      ? EXCHANGE_NETWORKS
      : ["RISK", "PUBK"].indexOf(sourceType) > -1
        ? BLOCKCHAIN_NETWORKS.filter((network) => network.value === "BTC")
        : sourceType === "SIGN"
          ? BLOCKCHAIN_NETWORKS.filter((network) => network.value === "ETH")
          : BLOCKCHAIN_NETWORKS;

          useEffect(() => {
            if (isSingle) {
              setValue('min', 1); // Set 'min' to 1
              setValue('max', 1); // Set 'max' to 1
            }
          }, [isSingle, setValue]);
        

  switch (fieldType) {
    case "address":
      return (
        <FormField
          control={control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Address</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "secret":
      return (
        <FormField
          control={control}
          name="secret"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Secret</FormLabel>
              <FormControl>
                <Input {...field} type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "public":
      return (
        <FormField
          control={control}
          name="public"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Public Key</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "name":
      return (
        <FormField
          control={control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Account Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "private":
      return (
        <FormField
          control={control}
          name="private"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Private Key</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "passphrase":
      return selectedNetwork === "OKX" ? (
        <FormField
          control={control}
          name="passphrase"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Passphrase</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      ) : null;
    case "key":
      return (
        <FormField
          control={control}
          name="key"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Key/Client ID</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
      case "network":
        return isSubaccountForm ? null : (
          <FormField
            control={control}
            name="network"
            render={({ field }) => {
              const handleNetworkChange = async (newValue: string) => {
                await fetchAssetsForNetwork(newValue);
                field.onChange(newValue);
              };
              return (
                <FormItem className="w-full my-2">
                  <FormLabel>
                    {sourceType === "EXCH" ? "Exchange" : "Network"}
                  </FormLabel>
                  <div className="my-4">
                    <NetworkSelect
                      networks={networksMaster}
                      onValueChange={handleNetworkChange}
                      title="Select Network"
                      description="Choose one of the available networks."
                      initialValue={field.value || ""}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      name={field.name}
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        );
    case "asset":
      return (
        <FormField
          control={control}
          name="asset"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Asset</FormLabel>
              <div className="my-4">
                <NetworkSelect
                  networks={assetsForNetwork}
                  onValueChange={field.onChange}
                  title="Select Asset"
                  description="Choose one of the available assets."
                  initialValue={field.value || ""}
                />
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "message":
      return (
        <FormField
          control={control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Message</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "signature":
      return (
        <FormField
          control={control}
          name="signature"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Signature</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "pubkey":
      return (
        <FormField
          control={control}
          name="pubkey"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Public Key</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "single":
      return (
        <FormField
          control={control}
          name="single"
          render={({ field }) => (
            <FormItem className="w-full my-4">
              <FormLabel>Single Signature?</FormLabel>
              <FormControl className="m-2 mt-4">
                <Switch
                  checked={field.value}
                  onCheckedChange={(value) => field.onChange(value)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );

    case "min":
        return isSingle ? null : (
        <FormField
          control={control}
          name="min"
          render={({ field }) => (
            <FormItem className="w-full my-4">
              <FormLabel>Minimum Signers</FormLabel>
              <FormControl>
              <Input {...field} type="number" value={getValues('min')} disabled={isSingle} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );

    case "max":
        return isSingle ? null : (
        <FormField
          control={control}
          name="max"
          render={({ field }) => (
            <FormItem className="w-full my-4">
              <FormLabel>Maximum Signers</FormLabel>
              <FormControl>
              <Input {...field} type="number" value={getValues('max')} disabled={isSingle} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "xpubkey":
      return (
        <FormField
          control={control}
          name="xpubkey"
          render={({ field }) => (
            <FormItem>
              <FormLabel>xPub Key</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "numberofaddresses":
      return (
        <FormField
          control={control}
          name="numberofaddresses"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Number of Addresses</FormLabel>
              <FormControl>
                <Input {...field} type="number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    default:
      return null;
  }
};
