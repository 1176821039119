/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from "@/components/ui/form";
import { LOADING_TEXT } from "@/config/general";
import { FC, useCallback, useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import useAuthStore from "@/stores/auth.store";
import useSourcesStore from "@/stores/sources.store";
import { getSchemasBySourceType } from "@/zod/source.zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FormFieldRenderer } from "./FormFieldRenderer";
import { SourceEntry, SourceFormType, Subaccount } from "@/types/sources.type";
import { extractFields } from "@/lib/utils";
import { SubaccountManager } from "@/components/stepper/modals/SubaccountManager";
import { SourceEntryCard } from "@/components/misc/SourceEntryCard";
import { KeysManager } from "./KeysManager";

const formFieldMappings: any = {
  BALA: ["network", "asset", "address"],
  EXCH: ["network", "public", "private", "passphrase", "issubaccount", "name"],
  RISK: ["network", "address"],
  SIGN: ["network", "message", "signature", "pubkey"],
  PUBK: ["network", "single", "min", "max"],
};

interface Asset {
  value: string;
  img: string;
  label: string;
}
function updateFormFieldMappings(
  networksData: any,
  sourceType: string,
  network: string
) {

  if (sourceType === "EXCH") {
    const requirements =
      networksData?.types[sourceType]?.[network]?.requirements || [];

    if (!Array.isArray(requirements)) {
      return;
    }
    const formFieldMappingsValidated = { ...formFieldMappings };
    formFieldMappingsValidated[sourceType] = ["network", ...requirements];
    return formFieldMappingsValidated;
  }

  return formFieldMappings;
}

function getDefaultValues(
  sourceType: string,
  sourceEntry: any,
  formFieldMappings: any
) {
  const fieldNames = formFieldMappings[sourceType] || [];
  const defaultValues: any = {};

  fieldNames.forEach((fieldName: string) => {
    defaultValues[fieldName] = sourceEntry?.[fieldName] || "";
  });

  return defaultValues;
}
interface SourceEntriesFormProps {
  entryId?: string | null;
  sourceType: string;
  setIsDialogOpen?: (value: boolean) => void;
}

export const SourceEntriesForm: FC<SourceEntriesFormProps> = ({
  entryId,
  sourceType,
  setIsDialogOpen,
}) => {
  const { toast } = useToast();

  const { source, editSourceEntry, createSourceEntry } = useSourcesStore();
  const [showForm, setShowForm] = useState(!entryId || sourceType !== "EXCH");

  const sourceEntry = source && (source.entry as SourceEntry);
  const handleSubaccountsChange = (newSubaccounts: Subaccount[]) => {
    setSubaccounts(newSubaccounts);
  };

  const [networks] = useSourcesStore((state) => [state.networks]);
  const [assetsForNetwork, setAssetsForNetwork] = useState<Asset[]>([]);
  const [dynamicFormFields, setDynamicFormFields] = useState(formFieldMappings);
  const [defaultValues, setDefaultValues] = useState(
    getDefaultValues(sourceType, sourceEntry, dynamicFormFields)
  );
  const [keys, setKeys] = useState<string[]>([]);
  const [subaccounts, setSubaccounts] = useState<Subaccount[] | []>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const email = useAuthStore((state) => state.user?.email);
  const sourceGroupId = useSourcesStore((state) => state.selectedSourceGroupId);

  const schema: any = getSchemasBySourceType(sourceType);
  const sourceForm = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const watchSingle = sourceForm.watch("single", false);
  const watchMax = sourceForm.watch("max", 1);
  console.log("watchcccc max", watchMax);
   useEffect(() => {
    if (watchSingle && keys.length > 1) {
      setKeys([keys[0]]);
    }
  }, [watchSingle, keys]);


  const handleEdit = () => {
    setShowForm(true);
  };

  const handleKeysChange = (newKeys: string[]) => {
    setKeys(newKeys);
  };


  const fetchAssetsForNetwork = useCallback(
    async (network: string) => {
      setDynamicFormFields(
        updateFormFieldMappings(networks, sourceType, network)
      );
      setDefaultValues(
        getDefaultValues(sourceType, sourceEntry, dynamicFormFields)
      );

      if (sourceType !== "BALA") return;

      try {
        const networkRef = networks[sourceType].find(
          (item: any) => item.symbol === network
        );

        const assets: Asset[] = [
          ...(networkRef?.native
            ? [
                {
                  value: networkRef.native.asset,
                  img: networkRef.native.icon,
                  label: networkRef.native.name,
                },
              ]
            : []),
          ...(networkRef?.tokens || []).map((token: any) => ({
            value: token.asset,
            img: token.icon,
            label: token.name,
          })),
          ...(networkRef?.nfts || []).map((nft: any) => ({
            value: nft.asset,
            img: nft.icon,
            label: nft.name,
          })),
        ];

        setAssetsForNetwork(assets);
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    },
    [dynamicFormFields, networks, sourceEntry, sourceType]
  );

  useEffect(() => {
    if (entryId && sourceEntry) {
      sourceForm.reset(sourceEntry || {});
      setSubaccounts((sourceEntry && sourceEntry?.subaccounts) || []);
      setKeys(sourceEntry.keys || []);

    } else {
      sourceForm.reset({});
      setSubaccounts([]);
      setKeys([]);

    }
  }, [entryId, sourceEntry, sourceForm]);


  useEffect(() => {
    if (sourceEntry?.network) {
      fetchAssetsForNetwork(sourceEntry?.network);
    }
  }, [fetchAssetsForNetwork, sourceEntry]);

  const onSubmit = async (data: SourceFormType) => {
    try {
      setLoading(true);
      if (!email) return;

      const fields = dynamicFormFields[sourceType] || [];
      const mainAccountData = extractFields(data, fields);

      const submissionData = {
        ...mainAccountData,
        name: "main",
        issubaccount: false,
        subaccounts: sourceType === "EXCH" && subaccounts.map((subaccount) => ({
          ...subaccount,
          issubaccount: true,
        })),
        keys: sourceType === "PUBK" ? keys : undefined,

      };

      if (entryId) {
        await editSourceEntry(entryId, submissionData);
      } else {
        await createSourceEntry(
          sourceGroupId,
          submissionData,
          sourceType
        );
      }

      setIsDialogOpen && setIsDialogOpen(false);
      toast({
        title: `Entry ${entryId ? "Updated" : "Created"}`,
        description: `Your source entry has been ${entryId ? "updated" : "created"}.`,
      });
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!showForm && sourceEntry ? (
        <>
          <SourceEntryCard sourceEntry={sourceEntry} onEdit={handleEdit} />
          {sourceType === "EXCH" && (
            <SubaccountManager
              main={sourceEntry}
              sourceType={sourceType}
              subaccounts={subaccounts}
              onSubaccountsChange={handleSubaccountsChange}
              dynamicFormFields={dynamicFormFields}
              assetsForNetwork={assetsForNetwork}
              fetchAssetsForNetwork={fetchAssetsForNetwork}
            />
          )}

            <div className="flex my-2">
          <Button type="button" disabled={loading} className="mt-4"
               onClick={(e) => {
                e.preventDefault();
              sourceForm.handleSubmit(onSubmit)(e);
               }}
          >
            {loading ? LOADING_TEXT : entryId ? "Update Entry" : "Create Entry"}
          </Button>
            </div>
        </>
      ) : (
        <Form {...sourceForm}
        >
          <form
          className="m-2"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {" "}
            {error && <p>{error}</p>}
            {dynamicFormFields[sourceType]?.map((fieldType: string) => (
              <FormFieldRenderer
                isSubaccountForm={false}
                key={fieldType}
                fieldType={fieldType}
                control={sourceForm.control}
                sourceType={sourceType}
                assetsForNetwork={assetsForNetwork}
                fetchAssetsForNetwork={fetchAssetsForNetwork}
              />
            ))}
            {sourceType === "EXCH" && (
              <SubaccountManager
                main={sourceEntry || null}
                sourceType={sourceType}
                subaccounts={subaccounts}
                onSubaccountsChange={handleSubaccountsChange}
                dynamicFormFields={dynamicFormFields}
                assetsForNetwork={assetsForNetwork}
                fetchAssetsForNetwork={fetchAssetsForNetwork}
              />
            )}
            {sourceType === "PUBK" && (
              <KeysManager
              single={watchSingle}
              max={Number(watchMax)}
              keys={keys}
              onKeysChange={handleKeysChange}
              />
            )}
            <div className="flex my-2">
            <Button type="button" disabled={loading} className="mt-4"
               onClick={(e) => {
                e.preventDefault();
              sourceForm.handleSubmit(onSubmit)(e);
               }}
            >
              {loading
                ? LOADING_TEXT
                : entryId
                  ? "Update Entry"
                  : "Create Entry"}
            </Button>
            </div>
          </form>
        </Form>
      )}
    </>
  );
};
