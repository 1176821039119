import React from 'react';
import { Badge } from "@/components/ui/badge"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

interface KeyDisplayProps {
  keys: string[];
}

const KeyDisplay: React.FC<KeyDisplayProps> = ({ keys }) => {
  const shortenKey = (key: string): string => {
    return `${key.slice(0, 15)}...${key.slice(-15)}`;
  };

  return (
    <div className="flex flex-wrap gap-2">
      {keys.map((key, index) => (
        <TooltipProvider key={index}>
          <Tooltip>
            <TooltipTrigger>
            <Badge 
                className="inline-flex items-center bg-gray-50 dark:bg-gray-700 rounded-full px-2 py-1 dark:border-gray-700 text-xs font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10"
                variant="outline"
              >
                {shortenKey(key)}
              </Badge>
            </TooltipTrigger>
            <TooltipContent className='bg-muted-foreground'>
              <p className="max-w-xs break-all">{key}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ))}
    </div>
  );
};

export default KeyDisplay;