import React from 'react';
interface BalanceCellProps {
  value: number;
}

const DisplayCell: React.FC<BalanceCellProps> = ({ value }) => {

  return (
          <div className="mx-8">
            {value}
          </div>
  );
};

export default DisplayCell;