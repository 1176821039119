import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  PlusCircle,
  MoreVertical,
  Copy,
  Pencil,
  Trash2,
  Check,
  X,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Badge } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  modify_add_key_function,
  modify_save_edit_function,
  validate_input_state,
} from "@/lib/utils";

interface KeysManagerProps {
  keys: string[];
  onKeysChange: (newKeys: string[]) => void;
  single: boolean;
  max: number;
}

export const KeysManager: React.FC<KeysManagerProps> = ({
  keys,
  onKeysChange,
  single,
  max,
}) => {
  const [newKey, setNewKey] = useState("");
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingValue, setEditingValue] = useState("");

  const addKey = () => {
    const updatedKeys = modify_add_key_function(newKey, keys, single, max);
    if (updatedKeys.length > keys.length) {
      onKeysChange(updatedKeys);
      setNewKey("");
    }
  };

  const startEditing = (index: number) => {
    setEditingIndex(index);
    setEditingValue(keys[index]);
  };

  const cancelEditing = () => {
    setEditingIndex(null);
    setEditingValue("");
  };

  const saveEdit = () => {
    const updatedKeys = modify_save_edit_function(
      editingIndex,
      editingValue,
      max,
      keys
    );
    if (updatedKeys !== keys) {
      onKeysChange(updatedKeys);
      setEditingIndex(null);
      setEditingValue("");
    }
  };

  const deleteKey = (index: number) => {
    const updatedKeys = keys.filter((_, i) => i !== index);
    onKeysChange(updatedKeys);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const shortenKey = (key: string) => {
    return key.length > 25 ? `${key.slice(0, 25)}...` : key;
  };

  const isInputDisabled = validate_input_state(single, keys, max);

  return (
    <div className="space-y-4">
      {!isInputDisabled && (
        <div className="flex items-center space-x-2 border-b py-4">
          <Textarea
            value={newKey}
            onChange={(e) => setNewKey(e.target.value)}
            placeholder={`Enter a key`}
            disabled={isInputDisabled}
          />
          <Button
            onClick={addKey}
            type="button"
            size="sm"
            variant="ghost"
            disabled={isInputDisabled}
          >
            <PlusCircle className="h-4 w-4 mr-2" />
            Add Key
          </Button>
        </div>
      )}
      {keys.length > 0 ? (
        <div className="space-y-2">
          {keys.map((key, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-2 border dark:border-gray-700 rounded-md"
            >
              {editingIndex === index ? (
                <>
                  <Textarea
                    value={editingValue}
                    onChange={(e) => setEditingValue(e.target.value)}
                    className="mr-2"
                    placeholder={`Key`}
                  />
                  <div>
                    <Button
                      type="button"
                      onClick={saveEdit}
                      size="sm"
                      className="mr-1"
                      variant="ghost"
                    >
                      <Check className="h-3.5 w-3.5" />
                    </Button>
                    <Button
                      type="button"
                      onClick={cancelEditing}
                      size="sm"
                      variant="ghost"
                    >
                      <X className="h-3.5 w-3.5" />
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Badge
                          className="inline-flex items-center bg-gray-50 dark:bg-gray-700 rounded-full px-2 py-1 dark:border-gray-700 text-xs font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10"
                          variant="outline"
                        >
                          {shortenKey(key)}
                        </Badge>
                      </TooltipTrigger>
                      <TooltipContent className="bg-muted-foreground">
                        <p className="max-w-xs break-all">{key}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="sm">
                        <MoreVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem onClick={() => copyToClipboard(key)}>
                        <Copy className="h-4 w-4 mr-2" />
                        Copy
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => startEditing(index)}>
                        <Pencil className="h-4 w-4 mr-2" />
                        Edit
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => deleteKey(index)}
                        disabled={keys.length <= max}
                      >
                        <Trash2 className="h-4 w-4 mr-2" />
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center border rounded-md my-2 border-dotted py-2 bg-muted">
          <p className="text-sm text-muted-foreground">No keys added yet</p>
        </div>
      )}
    </div>
  );
};
