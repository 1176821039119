import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Check, Clipboard} from "lucide-react"; 
import * as React from "react";

interface CellProps {
  fullText: string;
  isActive: boolean; 
}

const WorkspaceName: React.FC<CellProps> = ({ fullText, isActive}) => {

  const displayText = isActive 
    ? '•'.repeat(Math.min(fullText.length, 35)) 
    : (fullText.length > 10 ? `${fullText.slice(0, 35)}...` : fullText);

  const [isOpen, setIsOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const handleMouseEnter = () => {
    if (!isActive) setIsOpen(true);
  };

  const handleMouseLeave = () => {
    if (!isActive) setIsOpen(false);
  };

  const handleCopy = (text: string) => {
    if (!isActive) {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const content = (
    <div
      className="flex space-x-2 cursor-pointer text-muted-foreground text-sm my-2"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span>{displayText}</span>
      {!isActive && (
        copied ? (
          <Check className="h-3.5 w-3.5 text-green-500" />
        ) : (
          <Clipboard
            className="h-3.5 w-3.5 text-gray-700 dark:text-gray-400"
            onClick={(e) => {
              e.stopPropagation();
              handleCopy(fullText);
            }}
          />
        )
      )}
    </div>
  );

  if (isActive) {
    return content;
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        {content}
      </PopoverTrigger>
      <PopoverContent className="w-full max-w-2xl p-2">
        <div className="flex flex-col space-y-2">
          <span className="break-words text-sm text-muted-foreground">
            {fullText}
          </span>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default WorkspaceName;