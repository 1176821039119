import React from 'react';

interface CellProps {
  value: number | string | boolean; // Allow number, string, or boolean types
}

const BooleanCell: React.FC<CellProps> = ({ value }) => {
  const displayValue = typeof value === 'boolean'
    ? (value ? 'Yes' : 'No')
    : (typeof value === 'string' && (value === 'true' || value === 'false'))
      ? (value === 'true' ? 'Yes' : 'No')
      : value;

  return (
    <div className="mx-8">
      {displayValue}
    </div>
  );
};

export default BooleanCell;