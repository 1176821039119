import * as React from "react";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

interface CellProps {
  isActive: boolean; 
  id: number; 
}

const WorkspaceIcon: React.FC<CellProps> = ({ isActive, id}) => {

  const content = (
    <div
      className="flex items-center space-x-2 cursor-pointer text-muted-foreground text-sm my-2"
    >
                        <Avatar className="mr-2 h-5 w-5">
                          <AvatarImage
                            src={`https://avatar.vercel.sh/${id}.png`}
                            alt={`${id}`}
                            className={`${isActive? '': 'grayscale'}`}
                          />
                          <AvatarFallback>WS</AvatarFallback>
                        </Avatar>
    </div>
  );
    return content;
};

export default WorkspaceIcon;